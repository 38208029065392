<template>
  <div class="statement-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "账单列表",
        search: [
          {
            key: "date",
            label: "对账日期",
            type: "daterange",
            timeType: 'yyyy-MM-dd' 
          },
          {
            key: "status",
            label: "对账状态",
            type: "select-no-filterable",
            opt: {
              list: [
                {
                  label: "正常",
                  value: 1
                },
                {
                  label: "异常",
                  value: 2
                },
                {
                  label: "已冲正",
                  value: 3
                }
              ]
            }
          }
        ],
        columns: [
          {
            label: "对账日期",
            key: "reconDate",
            on: row => {
              _this.$router.push("/main/statement/detail/show/" + row.id + '?date=' + row.reconDate);
            }
          },
          { label: "订单数", key: "orderSum" },
          { label: "订单总金额", key: "orderTotalFee" },
          { label: "到账总金额(元)", key: "payTotalFee" },
          {
            label: "对账状态",
            key: "reconState",
            getClass(item) {
              return item.status == 2 ? "danger" : "";
            }
          }
        ]
      }
    };
  },
  created() {
    console.log("statement-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit,
        startDate: opt.searchForm['date'] ? opt.searchForm['date'][0] : null,
        endDate: opt.searchForm['date'] ? opt.searchForm['date'][1] : null,
        reconState: opt.searchForm['status']
      };
      this.post("/finance-service/reconStatisticsDay/searchPageReconStatisticsDay", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.reconDate = this.format(item.reconDate, 1);
          item.reconState = ["正常", "异常", "已冲正"][item.reconState - 1];
          if (item.reconState == 2) {
            item.bk = 3;
          }
          item.orderTotalFee = parseFloat(item.orderTotalFee).toFixed(2);
          item.payTotalFee = parseFloat(item.payTotalFee).toFixed(2);
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>